import axiosClientV2 from "./axiosClientV2";

const notiApi = {
  pushNoti: (body) => {
    const url = "/notifications";
    return axiosClientV2.post(url, body);
  },
  getNoti: () => {
    const url = "/notifications";
    return axiosClientV2.get(url);
  },
};

export default notiApi;
