import axios from "axios";
import { API_BASE_URL_V2 } from "../config";
import queryString from "query-string";
import { USER_TOKEN } from "utils/storage";
// Set up default config for http requests here

// Please have a look at here `https://github.com/axios/axios#request-
// config` for the full list of configs
const axiosClientV2 = axios.create({
  baseURL: API_BASE_URL_V2,
  headers: {
    "content-type": "application/json",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClientV2.interceptors.request.use(async (config) => {
  const token = USER_TOKEN.get();
  if (token) {
    config.headers.Authorization = token;
  }
  // check sever side
  if (typeof window !== "undefined") {
    // config formData
    if (config?.customUrl) {
      config.baseURL = config.customUrl;
    }
    if (config?.wallet) {
      config.headers.wallet = config.wallet;
    }
    if (config?.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
  }
  return config;
});

axiosClientV2.interceptors.response.use(
  (response) => {
    if (typeof response?.data?.payload !== "undefined") {
      return response.data.payload;
    }

    if (response?.data) {
      return response.data;
    }

    return response;
  },
  (error) => {
    console.log("ssss", error);
    // Handle errors
    throw error;
  }
);

export default axiosClientV2;
