import Container from "components/Container/Container";
import { useForm } from "react-hook-form";
import { Switch } from "@headlessui/react";
import { useState } from "react";
import notiApi from "api/notiApi";
import SpinnerLoading from "components/SpinnerLoading";
import { showToastSuccess } from "components/CustomToast/CustomToast";
import { showToastError } from "components/CustomToast/CustomToast";

const Notification = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    if (!enabled) {
      data.sendTo = "all";
    }
    try {
      const res = await notiApi.pushNoti(data);
      showToastSuccess(res?.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToastError("Push notification fail");
      console.error(error);
    }
  };

  return (
    <Container className="">
      <div className="w-[80%] h-[80%] bg-white m-auto rounded-2xl pt-3">
        <h3 className="text-center text-black mt-8 mb-14 font-semibold text-2xl">
          Push Notification
        </h3>

        <div className=" w-[70%] m-auto mb-7 flex items-center justify-start gap-3">
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={`${
              enabled ? "bg-blue-600" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span
              className={`${
                enabled ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
          <span className="font-semibold text-[#537CA5]">
            {!enabled ? "Send All" : "Send One"}
          </span>
        </div>

        <form
          className="w-[70%] m-auto flex flex-col gap-7"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            className={`${
              errors.sendTo && enabled && "border-danger"
            } p-2 px-4 bg-transparent rounded-xl w-full border focus:outline-none`}
            placeholder={enabled ? "Please enter user ID..." : "All"}
            disabled={!enabled}
            type="text"
            {...register("sendTo", { required: enabled })}
          />

          <input
            className={`${
              errors.title && "border-danger"
            } p-2 px-4 bg-transparent rounded-xl w-full border focus:outline-none`}
            placeholder="Please enter title..."
            type="text"
            {...register("title", { required: true })}
          />
          <textarea
            rows={7}
            placeholder="Please enter message..."
            className={`${
              errors.body && "border-danger"
            } p-2 px-4 bg-transparent rounded-xl w-full border focus:outline-none`}
            {...register("body", { required: true })}
          />

          <button
            type="submit"
            className="flex justify-center items-center gap-3 my-7 mb-20 p-2 border-solid border-[#537CA5] border-[2px] rounded-full font-bold text-xl hover:bg-[#537ca563] transition-all"
          >
            Push
            {loading && <SpinnerLoading className={"w-[20px]"} />}
          </button>
        </form>
      </div>
    </Container>
  );
};

export default Notification;
